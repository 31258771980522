import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Time from '../Time'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'

const PostListItem = ({ post }) => {
    return (
        <div className="columns is-multiline" key={post.id}>
            <div className="column is-6" key={post.id}>
                <div className="container is-mobile is-transition">
                    <article className="tile is-child white-ter">
                        <Link to={post.fields.slug}>
                            <figure className="is-3by2">
                                <Img
                                    alt={post.frontmatter.imageAlt}
                                    fluid={
                                        post.frontmatter.image.childImageSharp
                                            .fluid
                                    }
                                />
                            </figure>
                        </Link>
                        <div className="has-article-content-padding">
                            <div className="level is-mobile">
                                <div className="level-left">
                                    <p className="is-size-7 is-blog-component-color tag is-dark">
                                        {post.frontmatter.tags}
                                    </p>
                                </div>
                                <div className="level-right">
                                    <div className="is-size-7 is-blog-component-color tag is-dark">
                                        <Time
                                            pubdate
                                            langKey={post.fields.langKey}
                                            date={post.frontmatter.date}
                                        />
                                    </div>
                                </div>
                            </div>
                            <h2>
                                <Link
                                    className="has-text-weight-normal is-blog-title-color is-size-5"
                                    to={post.fields.slug}
                                >
                                    {post.frontmatter.title}
                                </Link>
                            </h2>
                            <p className="mt-4">
                                <Link
                                    className="content has-text-weight-normal is-blog-subtitle-color is-size-6"
                                    to={post.fields.slug}
                                >
                                    {post.excerpt}
                                </Link>
                            </p>
                            <div className="level is-mobile mt-5">
                                <div className="level-left">
                                    <figure className="image is-32x32 is-margin-auto-zero">
                                        <Img
                                            className="is-image-radius-circle"
                                            fluid={
                                                post.frontmatter.authorImage
                                                    .childImageSharp.fluid
                                            }
                                        />
                                    </figure>
                                    <p className="is-size-7 ml-3 is-blog-component-color has-text-weight-medium">
                                        {post.frontmatter.author}
                                    </p>
                                </div>
                                <div className="level-right">
                                    <p className="is-size-7 is-blog-component-color has-text-weight-medium">
                                        {post.timeToRead}{' '}
                                        <FormattedMessage id="blog.time.read" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}

PostListItem.propTypes = {
    post: PropTypes.shape({
        id: PropTypes.string,
        fields: PropTypes.shape({
            slug: PropTypes.string.isRequired,
            langKey: PropTypes.string.isRequired,
        }),
        frontmatter: PropTypes.shape({
            title: PropTypes.string.isRequired,
            author: PropTypes.string.isRequired,
            imageAlt: PropTypes.string.isRequired,
            authorImage: PropTypes.object.isRequired,
            image: PropTypes.object.isRequired,
            tag: PropTypes.array,
            tags: PropTypes.array,
            date: PropTypes.string.isRequired,
        }),
        excerpt: PropTypes.string.isRequired,
        timeToRead: PropTypes.number,
    }),
}

export default PostListItem
